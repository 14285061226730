
import { Vue, Options } from 'vue-class-component';
import axios, { CancelTokenSource } from 'axios';
import SingleLayout from '@/lib/layouts/SingleLayout.vue';
import { PageLoading } from '@/lib/components';
import { Patient } from '@/models';
import { OrganisationPatientService } from '@/services/api';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    }
  },
  components: {
    SingleLayout,
    PageLoading
  },
  provide() {
    return {
      updatePatient: this.update
    };
  }
})
export default class PatientPage extends Vue {
  organisationId!: string;
  patientId!: string;
  loading = false;
  patient: Patient | null = null;
  loadedPatientId = '';
  request: CancelTokenSource | null = null;
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  get organisation() {
    return this.sessionStore.getOrganisation(this.organisationId);
  }

  get service(): OrganisationPatientService {
    return new OrganisationPatientService(this.organisationId);
  }

  created() {
    this.$watch('organisationId', () => {
      this.fetchPatient(this.patientId);
    }, { immediate: true });
  }

  unmounted() {
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchPatient(patientId: string | null) {
    const targetPatientId = patientId ?? this.patientId;
    // TODO - Work out what to do for loading state, especially for the child components that are expecting patient object on page load
    try {
      this.request = axios.CancelToken.source();
      const patient = await this.service.fetch(targetPatientId, {
        cancelToken: this.request.token
      });
      this.request = null;

      if (patient.merged_patient) {
        await this.fetchPatient(patient.merged_patient.id);
        this.$router.replace({
          path: this.$route.path.replace(patient.id, patient.merged_patient.id)
        });
        return;
      }

      this.patient = patient;

      this.loadedPatientId = patient.id;
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.patient.fetch-one-error')
        });
      }
    }
  }

  update(patient: Patient) {
    this.patient = patient;
  }
}
